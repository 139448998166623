<template>
  <section class="card">
    <transition name="modal" mode="in-out">
      <modal v-if="modals.show"
             :closemodal="cancelar"
             @salvar="salvar"
             :errors="this.$root.errors"
             :title="itemSelecionado[0].Id !== 0 ? 'Editar: '+itemSelecionado[0].Nome : 'Cadastrar novo fornecedor'"
             :size="'modal-lg'">
        <template slot="body">
          <pessoa-partial :params="{Cliente: obj}">
          </pessoa-partial>
        </template>
        <template  v-slot:footer="slotFooterProps">
          <button class="btn btn-sm pull-right btn-primary modal-confirm"
                  @click="slotFooterProps.clickHandler('salvar')">
            Salvar
          </button>
        </template>
      </modal>
    </transition>
    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header" >
        <span class="selecionado">
          {{ itemSelecionado.length && itemSelecionado[0].Id != 0 ? 'Selecionado: ' + itemSelecionado[0].Nome : '' }}
        </span>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <data-table :result="result"
                  :params="params"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :resolveStatusClass="resolveStatusClass"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :handleConsultar="handleConsultar">
      </data-table>
    </div>
  </section>
</template>

<script>
import pessoaPartial from '@/components/PessoaPartial.vue';
import consultar from '@/services/consultar.js';

//Fornecedores
export default {

    components: {
        ...consultar.components,
        'pessoa-partial': pessoaPartial,
    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/fornecedores/index',
            clone: '',
            obj: {},
            actionButtons:[
                {
                    text: 'Editar',
                    className: 'editar padrao',
                    icon: 'fas fa-edit',
                    color: 'default',
                    visible: false,// inicial
                    visibleOnRowSelected: true,
                    title: 'Editar item selecionado',
                    callback: that.handleEditarButton
                },
                {
                    text: '',
                    className: 'novo padrao',
                    icon: 'fas fa-plus',
                    color: 'default',
                    visible: true,
                    title: 'Incluir novo fornecedor',
                    callback: that.handleNovoButton
                },
                {
                    text: '',
                    className: 'excel padrao',
                    icon: 'fas fa-file-excel',
                    iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Exportar para excel',
                    callback: that.exportar
                },
            ],
            filtros:
            {
                data: [
                    {
                        id: 'nome',
                        title: 'Nome',
                        active: false,
                        placeholder: '',
                        //value: 'zueira'
                    },
                    {
                        id: 'cpfCnpj',
                        title: 'CPF/CNPJ',
                        active: false,
                        placeholder: '',
                        //value: 'zueira'
                    },
                ],
                visao: [
                    {
                        id: 0,
                        title: 'Todos fornecedores',
                        filtro: []
                    },

                ],
                visaoSelecionada: 0,
                visible: true
            },
            modals: {show:false}
        };
    },
    methods: {
        ...consultar.methods,
        handleEditarButton: function () {
            debugger;
            if (this.itemSelecionado.length) {
                this.$root.errors = [];

                if (!this.itemSelecionado[0].Estado) {
                    this.itemSelecionado[0].Estado = {
                        Descricao: ''
                    };
                }

                if (!this.itemSelecionado[0].Endereco.length) {
                    this.itemSelecionado[0].Endereco = [{
                        Cidade: {

                        },
                        CEP: '',
                        Bairro: '',
                        Logradouro: '',
                        Numero: '',
                    }];
                }
                else if (this.itemSelecionado[0].Endereco[0].Cidade) {
                    this.itemSelecionado[0].Estado = {
                        Id: this.itemSelecionado[0].Endereco[0].Cidade.Estado.Id,
                        Descricao: this.itemSelecionado[0].Endereco[0].Cidade.Estado.Descricao,
                    }
                    document.novaCidade = this.itemSelecionado[0].Endereco[0].Cidade.Descricao;
                }
                else {
                    this.itemSelecionado[0].Estado = {
                        Descricao: ''
                    }
                }

                this.obj = this.itemSelecionado[0];
                this.clone = JSON.stringify(this.itemSelecionado[0]);
                this.modals.show = true;
            }
        },
        handleNovoButton: function () {
            this.$root.errors = [];
            this.selected.pop();
            this.itemSelecionado.pop();
            this.itemSelecionado.push({
                Id: 0,
                Estado: {
                    Descricao: ''
                },
                Endereco: [{
                    Cidade: {

                    },
                    CEP: '',
                    Bairro: '',
                    Logradouro: '',
                    Numero: '',
                }],
                Contato: [{
                    TipoContato: 0,
                    Tratamento: 0
                }]
            })

            this.obj = this.itemSelecionado[0];
            this.modals.show = true;
        }

    },
    mounted: consultar.mounted
};
</script>
